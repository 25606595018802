body {
  margin: 0;
  font-family: franklin, Inter, Inter UI, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: inherit !important;
}

button {
  font-family: franklin, Inter, Inter UI, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* color: rgb(26, 26, 27); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: franklin;
  font-weight: 300;
  src: url(./fonts/franklin-normal-300.woff2);
  font-display: swap;
}

@font-face {
  font-family: franklin;
  font-weight: 400;
  src: url(./fonts/franklin-normal-400.woff2);
  font-display: swap;
}

@font-face {
  font-family: franklin;
  font-weight: 500;
  src: url(./fonts/franklin-normal-500.woff2);
  font-display: swap;
}

@font-face {
  font-family: franklin;
  font-weight: 600;
  src: url(./fonts/franklin-normal-600.woff2);
  font-display: swap;
}

@font-face {
  font-family: franklin;
  font-weight: 700;
  src: url(./fonts/franklin-normal-700.woff2);
  font-display: swap;
}

@font-face {
  font-family: karnak;
  font-weight: 500;
  src: url(./fonts/karnak-normal-500.woff2);
  font-display: swap;
}

@font-face {
  font-family: karnak-condensed;
  font-weight: 700;
  src: url(./fonts/karnakcondensed-normal-700.woff2);
  font-display: swap;
}
